<template>
  <a-drawer
    :destroy-on-close="true"
    :title="isUpdate ? '联系人编辑' : '联系人创建'"
    :visible="visible"
    :width="800"
    @close="closeDrawer"
  >
    <a-form-model
      ref="form"
      :label-col="{
        span: 4,
        style: {
          textAlign: 'left'
        }
      }"
      :model="form"
      :rules="rules"
      :wrapper-col="{
        span: 20
      }"
    >
      <a-form-model-item label="名称" prop="name">
        <a-input v-model="form.name" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item label="邮箱地址" prop="emailAddress">
        <a-input v-model="form.emailAddress" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item label="手机号码" prop="phoneNumber">
        <a-input v-model="form.phoneNumber" :max-length="11"></a-input>
      </a-form-model-item>
      <a-form-model-item prop="webhook">
        <question-icon
          slot="label"
          title="Webhook"
          description="支持钉钉、企业微信等 Webhook"
        ></question-icon>
        <a-input
          v-model="form.webhook"
          :max-length="128"
          placeholder="以 http:// 或 https:// 开头"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item
        :wrapper-col="{ span: 20, offset: 4 }"
        style="margin-bottom: 0"
      >
        <a-space size="middle">
          <a-button :loading="loading" type="primary" @click="ok">
            确定
          </a-button>
          <a-button @click="closeDrawer">取消</a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {
  createContact,
  getContact,
  updateContact
} from '@/api/contact'
import QuestionIcon from '@/components/icon/QuestionIcon'
import { validatePhoneNumber } from '@/utils/validates'

export default {
  name: 'InputDrawer',
  components: {
    QuestionIcon
  },
  data () {
    return {
      visible: false,
      isUpdate: false,
      form: {
        id: '',
        name: '',
        emailAddress: undefined,
        phoneNumber: undefined,
        webhook: undefined
      },
      rules: {
        name: [
          {
            message: '请输入名称',
            required: true,
            trigger: 'blur'
          }
        ],
        emailAddress: [
          {
            message: '请输入正确的邮箱地址',
            type: 'email',
            trigger: 'blur'
          }
        ],
        phoneNumber: [
          {
            validator: validatePhoneNumber,
            trigger: 'blur'
          }
        ],
        webhook: [
          {
            message: '请输入正确的 URL 地址',
            type: 'url',
            trigger: 'blur'
          }
        ]
      },
      loading: false
    }
  },
  methods: {
    show (id) {
      if (id) {
        this.isUpdate = true
        getContact(id)
          .then(res => {
            const data = res.data
            this.form = {
              id: data.id,
              name: data.name,
              emailAddress: data.email_address,
              phoneNumber: data.phone_number,
              webhook: data.webhook
            }
          })
          .finally(() => {
            this.visible = true
          })
      } else {
        this.isUpdate = false
        this.visible = true
      }
    },
    closeDrawer () {
      this.form = {
        id: '',
        name: '',
        emailAddress: undefined,
        phoneNumber: undefined,
        webhook: undefined
      }
      this.$refs.form.resetFields()
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const params = {
            name: this.form.name,
            email_address: this.form.emailAddress,
            phone_number: this.form.phoneNumber,
            webhook: this.form.webhook
          }
          this.loading = true
          if (this.isUpdate) {
            updateContact(this.form.id, params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.closeDrawer()
              })
              .finally(() => {
                this.loading = false
              })
          } else {
            createContact(params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.closeDrawer()
              })
              .finally(() => {
                this.loading = false
              })
          }
        }
      })
    }
  }
}
</script>
