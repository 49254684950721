var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-drawer',{attrs:{"destroy-on-close":true,"title":_vm.isUpdate ? '联系人编辑' : '联系人创建',"visible":_vm.visible,"width":800},on:{"close":_vm.closeDrawer}},[_c('a-form-model',{ref:"form",attrs:{"label-col":{
      span: 4,
      style: {
        textAlign: 'left'
      }
    },"model":_vm.form,"rules":_vm.rules,"wrapper-col":{
      span: 20
    }}},[_c('a-form-model-item',{attrs:{"label":"名称","prop":"name"}},[_c('a-input',{attrs:{"max-length":128},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('a-form-model-item',{attrs:{"label":"邮箱地址","prop":"emailAddress"}},[_c('a-input',{attrs:{"max-length":128},model:{value:(_vm.form.emailAddress),callback:function ($$v) {_vm.$set(_vm.form, "emailAddress", $$v)},expression:"form.emailAddress"}})],1),_c('a-form-model-item',{attrs:{"label":"手机号码","prop":"phoneNumber"}},[_c('a-input',{attrs:{"max-length":11},model:{value:(_vm.form.phoneNumber),callback:function ($$v) {_vm.$set(_vm.form, "phoneNumber", $$v)},expression:"form.phoneNumber"}})],1),_c('a-form-model-item',{attrs:{"prop":"webhook"}},[_c('question-icon',{attrs:{"slot":"label","title":"Webhook","description":"支持钉钉、企业微信等 Webhook"},slot:"label"}),_c('a-input',{attrs:{"max-length":128,"placeholder":"以 http:// 或 https:// 开头"},model:{value:(_vm.form.webhook),callback:function ($$v) {_vm.$set(_vm.form, "webhook", $$v)},expression:"form.webhook"}})],1),_c('a-form-model-item',{staticStyle:{"margin-bottom":"0"},attrs:{"wrapper-col":{ span: 20, offset: 4 }}},[_c('a-space',{attrs:{"size":"middle"}},[_c('a-button',{attrs:{"loading":_vm.loading,"type":"primary"},on:{"click":_vm.ok}},[_vm._v(" 确定 ")]),_c('a-button',{on:{"click":_vm.closeDrawer}},[_vm._v("取消")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }