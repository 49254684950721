<template>
  <div>
    <a-page-header
      class="page-header"
      :ghost="false"
      style="margin-bottom: 16px"
    >
      <template #title>
        <a-space size="middle">
          <a-icon type="alert"></a-icon>
          <span>联系人</span>
        </a-space>
      </template>
      <span>联系人包括邮箱、手机、Webhook 等。</span>
    </a-page-header>

    <a-card :bordered="false" style="margin-bottom: 16px">
      <list-layout
        :getTableDataFunc="getTableDataFunc"
        :columnDefaultSelected="columnDefaultSelected"
        :columnsAll="columnsAll"
        :toolsList="['setting', 'reload', 'search', 'leftTool']"
        :refresh="refresh"
        :defaultSelected="tableSelectData"
        @tableSelectData="(v) => tableSelectData = v"
      >
        <!-- 工具栏 -->
        <template #leftTool>
          <create-button
            v-permission="{action: 'base.contact.create', effect: 'disabled'}"
            @click="$refs.inputDrawer.show()"
          >
          </create-button>
        </template>
        <!-- 表格自定义列 -->
        <template #operation="{ slotProps }">
          <edit-button
            v-permission="{action: 'base.contact.update', effect: 'disabled'}"
            @click="$refs.inputDrawer.show(slotProps.record.id)"
          ></edit-button>
          <delete-button
            v-permission="{action: 'base.contact.delete', effect: 'disabled'}"
            @confirm="confirm(slotProps.record.id)"
          ></delete-button>
        </template>
      </list-layout>
    </a-card>

    <input-drawer ref="inputDrawer" @ok="confirm"></input-drawer>
  </div>
</template>

<script>
import { deleteContact, getContactList } from '@/api/contact'
import ListLayout from '@/components/ListLayout'
import DeleteButton from '@/components/button/DeleteButton'
import EditButton from '@/components/button/EditButton'
import CreateButton from '@/components/button/CreateButton'
import InputDrawer from './modules/InputDrawer'

export default {
  name: 'Contact',
  components: {
    DeleteButton,
    EditButton,
    InputDrawer,
    CreateButton,
    ListLayout
  },
  data () {
    return {
      getTableDataFunc: getContactList,
      columnsAll: [
        {
          dataIndex: 'name',
          title: '名称',
          fixed: 'left',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'email_address',
          title: '邮箱地址',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'emailAddress'
          }
        },
        {
          dataIndex: 'phone_number',
          title: '手机号码',
          width: 200,
          scopedSlots: {
            customRender: 'phoneNumber'
          }
        },
        {
          dataIndex: 'webhook',
          title: 'Webhook',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'webhook'
          }
        },
        {
          dataIndex: 'created_at',
          title: '创建时间',
          width: 200
        },
        {
          dataIndex: 'updated_at',
          title: '更新时间',
          width: 200
        }
      ],
      columnDefaultSelected: [
        'name',
        'email_address',
        'phone_number',
        'webhook'
      ],
      refresh: false,
      tableSelectData: []
    }
  },
  methods: {
    async confirm (id = '') {
      if (id) {
        await deleteContact(id).then(res => {
          this.$message.success(res.message)
        })
        this.tableSelectData.splice(this.tableSelectData.findIndex(item => item.key === id), 1)
      }
      this.refresh = !this.refresh
    }
  }
}
</script>
